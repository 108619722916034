import React from 'react';
import styled from 'styled-components';

const StyledPageTitleHeader = styled.h1`
  margin: 0;

  text-shadow: 2px 2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000,
    -2px -2px 0 #000000, 2px 0px 0 #000000, 0px 2px 0 #000000,
    -2px 0px 0 #000000, 0px -2px 0 #000000;

  &:hover {
    color: #ffffff;
  }

  @media (min-width: 800px) {
    text-shadow: none;
  }
`;

const PageTitleHeader = ({ children }) => {
  return <StyledPageTitleHeader>{children}</StyledPageTitleHeader>;
};

export default PageTitleHeader;
