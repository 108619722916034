import React from 'react';
import styled from 'styled-components';

const StyledNavigation = styled.nav`
  //width: 290px;
`;

const StyledNavigationMenu = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin: 0;
  padding: 1rem;

  height: 100vh;

  @media (min-width: 1000px) {
    margin: 0;
    padding: 2rem 3rem;
  }
`;

const Navigation = ({ children }) => {
  return (
    <StyledNavigation>
      <StyledNavigationMenu>{children}</StyledNavigationMenu>
    </StyledNavigation>
  );
};

export default Navigation;
