import React from 'react';
import styled from 'styled-components';

const StyledNavigationItemGroup = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin: 0;
  padding: 0;
`;

const NavigationItemGroup = ({ children }) => {
  return <StyledNavigationItemGroup>{children}</StyledNavigationItemGroup>;
};

export default NavigationItemGroup;
