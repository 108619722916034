import React, { useState } from 'react';
import styled from 'styled-components';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import {
  Navigation,
  NavigationItem,
  NavigationItemGroup,
} from '../components/Navigation';
import PageTitleHeader from '../PageTitleHeader';
import Modal from '../components/Modal';

import gttm1 from '../images/going-to-the-match/1.jpg';
import gttm2 from '../images/going-to-the-match/2.jpg';
import gttm3 from '../images/going-to-the-match/3.jpg';
import gttm4 from '../images/going-to-the-match/4.jpg';
import gttm5 from '../images/going-to-the-match/5.jpg';
import gttm6 from '../images/going-to-the-match/6.jpg';
import gttm7 from '../images/going-to-the-match/7.jpg';
import gttm8 from '../images/going-to-the-match/8.jpg';
import gttm9 from '../images/going-to-the-match/9.jpg';
import gttm10 from '../images/going-to-the-match/10.jpg';

import nl1 from '../images/non-league/1.jpg';
import nl2 from '../images/non-league/2.jpg';
import nl3 from '../images/non-league/3.jpg';
import nl4 from '../images/non-league/4.jpg';
import nl5 from '../images/non-league/5.jpg';
import nl6 from '../images/non-league/6.jpg';
import nl7 from '../images/non-league/7.jpg';
import nl8 from '../images/non-league/8.jpg';
import nl9 from '../images/non-league/9.jpg';
import nl10 from '../images/non-league/10.jpg';

import u1 from '../images/ultras/1.jpg';
import u2 from '../images/ultras/2.jpg';
import u3 from '../images/ultras/3.jpg';
import u4 from '../images/ultras/4.jpg';
import u5 from '../images/ultras/5.jpg';
import u6 from '../images/ultras/6.jpg';
import u7 from '../images/ultras/7.jpg';
import u8 from '../images/ultras/8.jpg';
import u9 from '../images/ultras/9.jpg';
import u10 from '../images/ultras/10.jpg';

import sa1 from '../images/street-art/1.jpg';
import sa2 from '../images/street-art/2.jpg';
import sa3 from '../images/street-art/3.jpg';
import sa4 from '../images/street-art/4.jpg';
import sa5 from '../images/street-art/5.jpg';
import sa6 from '../images/street-art/6.jpg';
import sa7 from '../images/street-art/7.jpg';
import sa8 from '../images/street-art/8.jpg';
import sa9 from '../images/street-art/9.jpg';
import sa10 from '../images/street-art/10.jpg';

import terr1 from '../images/terraces/1.jpg';
import terr2 from '../images/terraces/2.jpg';
import terr3 from '../images/terraces/3.jpg';
import terr4 from '../images/terraces/4.jpg';
import terr5 from '../images/terraces/5.jpg';
import terr6 from '../images/terraces/6.jpg';
import terr7 from '../images/terraces/7.jpg';
import terr8 from '../images/terraces/8.jpg';
import terr9 from '../images/terraces/9.jpg';
import terr10 from '../images/terraces/10.jpg';

const imagesgttm = [
  gttm1,
  gttm2,
  gttm3,
  gttm4,
  gttm5,
  gttm6,
  gttm7,
  gttm8,
  gttm9,
  gttm10,
];

const imagesnl = [nl1, nl2, nl3, nl4, nl5, nl6, nl7, nl8, nl9, nl10];

const imagesu = [u1, u2, u3, u4, u5, u6, u7, u8, u9, u10];

const imagessa = [sa1, sa2, sa3, sa4, sa5, sa6, sa7, sa8, sa9, sa10];

const imagesterr = [
  terr1,
  terr2,
  terr3,
  terr4,
  terr5,
  terr6,
  terr7,
  terr8,
  terr9,
  terr10,
];

const HeaderElement = styled.li`
  display: flex;
  flex-direction: column;

  @media (min-width: 700px) {
    flex-direction: row;
    align-items: center;
  }
`;

const StyledPhoto = styled.h2`
  margin: 0;
  color: #e74c3c;
  font-size: 3rem;

  @media (min-width: 700px) {
    margin: 0 0 0 2rem;
  }
`;

const IndexPage = () => {
  const [photoIndexgttm, setPhotoIndexgttm] = useState(0);
  const [isOpengttm, setIsOpengttm] = useState(false);
  const [photoIndexnl, setPhotoIndexnl] = useState(0);
  const [isOpennl, setIsOpennl] = useState(false);
  const [photoIndexu, setPhotoIndexu] = useState(0);
  const [isOpenu, setIsOpenu] = useState(false);
  const [photoIndexsa, setPhotoIndexsa] = useState(0);
  const [isOpensa, setIsOpensa] = useState(false);
  const [photoIndexterr, setPhotoIndexterr] = useState(0);
  const [isOpenterr, setIsOpenterr] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Layout>
      <SEO title="Portfolio" />
      <Navigation>
        <NavigationItemGroup>
          <HeaderElement>
            <PageTitleHeader>
              Przemek
              <br />
              Niciejewski
            </PageTitleHeader>
            <StyledPhoto>Photography</StyledPhoto>
          </HeaderElement>
        </NavigationItemGroup>
        <NavigationItemGroup>
          <NavigationItem to="#" onClick={() => setIsOpengttm(true)} button>
            going to the match
          </NavigationItem>
          <NavigationItem to="#" onClick={() => setIsOpennl(true)} button>
            non-league
          </NavigationItem>
          <NavigationItem to="#" onClick={() => setIsOpenu(true)} button>
            ultras
          </NavigationItem>
          <NavigationItem to="#" onClick={() => setIsOpensa(true)} button>
            street art
          </NavigationItem>
          <NavigationItem to="#" onClick={() => setIsOpenterr(true)} button>
            terraces
          </NavigationItem>
        </NavigationItemGroup>
        <NavigationItemGroup>
          <NavigationItem to="#" onClick={() => setModalOpen(true)} button>
            about me
          </NavigationItem>
        </NavigationItemGroup>
        <NavigationItemGroup>
          <NavigationItem to="mailto:przemek@niciejewski.art">
            <FontAwesomeIcon icon={faEnvelope} />
          </NavigationItem>
          <NavigationItem
            to="https://www.facebook.com/niciejewskiphotography/"
            blank
          >
            <FontAwesomeIcon icon={faFacebook} />
          </NavigationItem>
          <NavigationItem to="https://twitter.com/niciejewski1" blank>
            <FontAwesomeIcon icon={faTwitter} />
          </NavigationItem>
        </NavigationItemGroup>
      </Navigation>

      {modalOpen && (
        <Modal title=" " onModalDismiss={() => setModalOpen(false)}>
          <p>
            Football has resided in me for over 25 years, when the first time I
            watched (consciously) a football match in Wieluń (Poland). During
            this time I have seen countless number of matches, the non-league
            struggles in Poland or Germany and the big stadiums in Barcelona or
            Manchester. From the very beginning of my relationship with football
            I’ve been fascinated by everything that is happening around the
            stadiums.
          </p>
          <p>
            The native pub, the endemic coloring, tissue of specific human
            personalities. So I am interested in everything that has a profound
            impact on how terraces look and behave during the beautiful game.
          </p>
          <p>
            In Nottingham I met a man who hadn’t left the game of Forest more
            than 1500 times in a row. In Mönchegladbach someone collects match
            programmes of local Borussia over 40 years. There is a pub in
            Liverpool, close to Anfield Road , that for most of the regulars is
            a second home. Literally.
          </p>
          <p>
            Memory is fleeting. I collect the tickets, write a diary, but the
            photography, despite its limitations, arranges the memories in the
            best and emotional way. I try to capture this particular “moment”.
            The moment an individual happiness, collective ecstasy, but also
            sadness, or even suffering due to the unfavorable situation on the
            pitch.
          </p>
          <p>
            Football is something more than 90 minutes. For many people caught
            up in my photographs is the sense of life.
          </p>
        </Modal>
      )}

      {isOpengttm && (
        <Lightbox
          mainSrc={imagesgttm[photoIndexgttm]}
          nextSrc={imagesgttm[(photoIndexgttm + 1) % imagesgttm.length]}
          prevSrc={
            imagesgttm[
              (photoIndexgttm + imagesgttm.length - 1) % imagesgttm.length
            ]
          }
          onCloseRequest={() => setIsOpengttm(false)}
          onMovePrevRequest={() =>
            setPhotoIndexgttm(
              (photoIndexgttm + imagesgttm.length - 1) % imagesgttm.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndexgttm((photoIndexgttm + 1) % imagesgttm.length)
          }
        />
      )}

      {isOpennl && (
        <Lightbox
          mainSrc={imagesnl[photoIndexnl]}
          nextSrc={imagesnl[(photoIndexnl + 1) % imagesnl.length]}
          prevSrc={
            imagesnl[(photoIndexnl + imagesnl.length - 1) % imagesnl.length]
          }
          onCloseRequest={() => setIsOpennl(false)}
          onMovePrevRequest={() =>
            setPhotoIndexnl(
              (photoIndexnl + imagesnl.length - 1) % imagesnl.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndexnl((photoIndexnl + 1) % imagesnl.length)
          }
        />
      )}

      {isOpenu && (
        <Lightbox
          mainSrc={imagesu[photoIndexu]}
          nextSrc={imagesu[(photoIndexu + 1) % imagesu.length]}
          prevSrc={imagesu[(photoIndexu + imagesu.length - 1) % imagesu.length]}
          onCloseRequest={() => setIsOpenu(false)}
          onMovePrevRequest={() =>
            setPhotoIndexu((photoIndexu + imagesu.length - 1) % imagesu.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndexu((photoIndexu + 1) % imagesu.length)
          }
        />
      )}

      {isOpensa && (
        <Lightbox
          mainSrc={imagessa[photoIndexsa]}
          nextSrc={imagessa[(photoIndexsa + 1) % imagessa.length]}
          prevSrc={
            imagessa[(photoIndexsa + imagessa.length - 1) % imagessa.length]
          }
          onCloseRequest={() => setIsOpensa(false)}
          onMovePrevRequest={() =>
            setPhotoIndexsa(
              (photoIndexsa + imagessa.length - 1) % imagessa.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndexsa((photoIndexsa + 1) % imagessa.length)
          }
        />
      )}

      {isOpenterr && (
        <Lightbox
          mainSrc={imagesterr[photoIndexterr]}
          nextSrc={imagesterr[(photoIndexterr + 1) % imagesterr.length]}
          prevSrc={
            imagesterr[
              (photoIndexterr + imagesterr.length - 1) % imagesterr.length
            ]
          }
          onCloseRequest={() => setIsOpenterr(false)}
          onMovePrevRequest={() =>
            setPhotoIndexterr(
              (photoIndexterr + imagesterr.length - 1) % imagesterr.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndexterr((photoIndexterr + 1) % imagesterr.length)
          }
        />
      )}
    </Layout>
  );
};

export default IndexPage;
