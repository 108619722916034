import React from 'react';
import styled, { css } from 'styled-components';

const StyledNavigationItem = styled.li`
  margin: 0;
  padding: 0.5rem 0;

  list-style: none;

  & > a > span,
  & > button {
    padding-left: 0.5rem;
    border-left: 3px solid transparent;
  }

  &:hover > a > span,
  &:hover > button {
    border-left: 3px solid transparent;

    border-left-color: #30a07c;
    transition: border-left-color 0.1s ease-in-out;
  }

  a {
    padding: 0.5rem;

    &,
    &:hover,
    &:focus,
    &:visited {
      color: #ffffff;
      text-decoration: none;
    }
  }
`;

const NavigationItem = ({ children, to, onClick, blank, button }) => {
  if (button) {
    return (
      <StyledNavigationItem>
        <button onClick={onClick}>{children}</button>
      </StyledNavigationItem>
    );
  }

  if (to == null) {
    return <StyledNavigationItem>{children}</StyledNavigationItem>;
  } else {
    return (
      <StyledNavigationItem>
        <a href={to} onClick={onClick} target={blank ? '_blank' : ''}>
          <span>{children}</span>
        </a>
      </StyledNavigationItem>
    );
  }
};

export default NavigationItem;
