import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ModalBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  min-height: 100vh;

  padding: 1rem;

  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
`;

const StyledModal = styled.div`
  z-index: 5000;
  width: 100vw;

  padding: 0.5rem 1rem;

  background-color: #15181e;
  border-radius: 0.625rem;

  p {
    font-weight: 400;
  }

  @media (min-width: 800px) {
    width: 50vw;
  }

  @media (min-width: 1280px) {
    width: 25vw;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    padding: 0.5rem;
  }
`;

const ModalTitle = styled.h3`
  margin: 0;
  padding: 0.5rem 0;

  font-size: 1.5rem;
`;

const Modal = ({ title, children, onModalDismiss }) => {
  return (
    <ModalBackdrop>
      <StyledModal>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <button onClick={onModalDismiss}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </ModalHeader>
        {children}
      </StyledModal>
    </ModalBackdrop>
  );
};

export default Modal;
