import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import 'normalize.css';

import background from '../images/background.jpg';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    color: #ffffff;
    font-family: 'Merriweather', serif;
    font-weight: 900;
    
    background: #000000 url('${background}') no-repeat center center;
    
    @media (min-width: 1000px) {
      background: #000000 url('${background}') no-repeat right center;
    }
  }
  
  button {
    padding: 0;
    border: 0;
    outline: 0;
    background: transparent;
    cursor: pointer;
    color: #ffffff;
    font-weight: 900;
  }
`;

const StyledWrapper = styled.div`
  position: relative;
`;

const Layout = ({ children }) => {
  return (
    <StyledWrapper>
      <GlobalStyle />
      {children}
    </StyledWrapper>
  );
};

export default Layout;
